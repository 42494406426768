import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const API_URL_SALES = "http://localhost:8000/api";
// const token = localStorage.getItem("user_token");

export const getToken = () =>
  localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

// Functions Start //
export async function postMethodNormalAPI(url, body) {
  const apiData = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      "Content-Type": "application/json",
    },
  });
  return apiData;
}
export async function postMethodNormalAPISales(url, body) {
  const apiData = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      "Content-Type": "application/json",
    },
  });
  return apiData;
}
export async function postMethodNormalAPIPUT(url, body) {
  const apiData = await axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      "Content-Type": "application/json",
    },
  });
  return apiData;
}
export async function postMethodMultiPartAPI(url, body) {
  const apiData = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return apiData;
}
export async function getMethodNormal(
  url,
  token = localStorage.getItem("user_token")
) {
  // console.log("url", url);
  const apiData = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      "Content-Type": "application/json",
    },
  });
  return apiData;
}

// Functions end //
// API URLS Start

//login URL
const loginURL = `${API_URL}/admin/login`;
const citiesURL = `${API_URL}/cities`;
const blogsURL = `${API_URL}/getBlogs`;
const getJobsURL = `${API_URL}/getJobs`;
const categoriesURL = `${API_URL}/categories`;
const updateCategoryURL = `${API_URL}/updateCategory`;

//trips url
const getTripsWithTitleURL = `${API_URL}/getTripsWithTitle`;
const tripsURL = `${API_URL}/trips`;
const allTripsURL = `${API_URL}/allTrips`;
const tripByIDURL = `${API_URL}/trip/`;
const getBookingByCustomerIdURL = `${API_URL}/getBookingByCustomerId/`;
const tripByCityIdURL = `${API_URL}/tripByCityId/`;
const tripByCityNameURL = `${API_URL}/tripByCityName`;
const getPriceInfoURL = `${API_URL}/trips/getPriceInfo`;
const addCityToTripURL = `${API_URL}/trips/addCityToTrip`;
const addImageToTripURL = `${API_URL}/trips/addImageToTrip`;
const removeTripImageURL = `${API_URL}/trips/removeTripImage`;
const deleteCityFromTripURL = `${API_URL}/trips/deleteCityFromTrip`;
const updateTripDataURL = `${API_URL}/trips/updateTripData`;
const addDatesToTripsURL = `${API_URL}/trips/addDatesToTrips`;
const addPriceToTripsURL = `${API_URL}/trips/addPriceToTrips`;
const addScheduleToTripURL = `${API_URL}/trips/addScheduleToTrip`;
const sliderImagesURL = `${API_URL}/slider_images`;
const getbookingDetailsByIdURL = `${API_URL}/getbookingDetailsById`;
const updateBookingURL = `${API_URL}/updateBooking`;
const deleteSliderImageURL = `${API_URL}/deleteSliderImage/`;
const deleteVideoURL = `${API_URL}/deleteVideo/`;
const sectionsURL = `${API_URL}/sections`;
const getSectionByIdURL = `${API_URL}/sections/`;
const updateSectionURL = `${API_URL}/updateSection`;
const addTripInSectionsURL = `${API_URL}/addTripInSections`;
const deleteTripFromSectionURL = `${API_URL}/deleteTripFromSection`;
const addFaqToTripURL = `${API_URL}/trips/addFaqToTrip`;
const removeTripFaqURL = `${API_URL}/trips/removeTripFaq`;
const createEnquiryURL = `${API_URL}/createEnquiry`;
const createCancelationRequestURL = `${API_URL}/createCancelationRequest`;

const getTestimonialsURL = `${API_URL}/getTestimonials`;
const addTestimonialsURL = `${API_URL}/addTestimonials`;
const updateTestimonialsURL = `${API_URL}/updateTestimonials`;
const getVideosURL = `${API_URL}/getVideos`;
const addVideoURL = `${API_URL}/addVideo`;
const getTripsByCategoryIdURL = `${API_URL}/getTripsByCategoryId/`;
const createBookingURL = `${API_URL}/createBooking`;
const addToCartCustomerURL = `${API_URL}/addToCartCustomer`;
const getDynamicDataURL = `${API_URL}/getDynamicData`;
const getTeamURL = `${API_URL}/getTeam`;
const createLeadSales = `${API_URL_SALES}/leads/create`;

export async function getAllCities(token) {
  return getMethodNormal(citiesURL);
}
export async function getDynamicData(token) {
  return getMethodNormal(getDynamicDataURL);
}
export async function getAllBlogs(token) {
  return getMethodNormal(blogsURL);
}
export async function getJobs(token) {
  return getMethodNormal(getJobsURL);
}
export async function getTeam() {
  return getMethodNormal(getTeamURL);
}
export async function getVideos(token) {
  return getMethodNormal(getVideosURL);
}

export async function addCity(body) {
  return postMethodMultiPartAPI(citiesURL, body);
}
export async function updateCity(body) {
  return postMethodNormalAPIPUT(citiesURL, body);
}
export async function getbookingDetailsById(body) {
  return postMethodNormalAPI(getbookingDetailsByIdURL, body);
}
export async function updateBooking(body) {
  return postMethodNormalAPI(updateBookingURL, body);
}
//categories
export async function getAllCategories(token) {
  return getMethodNormal(categoriesURL);
}
export async function addCategory(body) {
  return postMethodMultiPartAPI(categoriesURL, body);
}
export async function updateCategory(body) {
  return postMethodMultiPartAPI(updateCategoryURL, body);
}

//trips
export async function getTestimonials(body) {
  return getMethodNormal(getTestimonialsURL);
}

export async function getTripsWithTitle(body) {
  return getMethodNormal(getTripsWithTitleURL);
}
export async function allTrips(body) {
  return getMethodNormal(allTripsURL);
}
export async function addTestimonials(body) {
  return postMethodMultiPartAPI(addTestimonialsURL, body);
}
export async function addVideo(body) {
  return postMethodNormalAPI(addVideoURL, body);
}
export async function addToCartCustomer(body) {
  return postMethodNormalAPI(addToCartCustomerURL, body);
}
export async function createEnquiry(body) {
  createNew(body);
  createSales(body);
}
export async function createNew(body) {
  return postMethodNormalAPI(createEnquiryURL, body);
}
export async function createSales(body) {
  const payload = {
    name: body?.name,
    mobile_number: body?.mobile,
    number_of_people: "1",
    trip_name: body?.trip,
    lead_description: body?.message,
    trip_date: "",
    trip_type: "fix",
    city: "Pune",
    lead_type: "Fixed Departure",
    status: "NEW",
    source: "Website",
  };
  return postMethodNormalAPISales(createLeadSales, payload);
}
export async function createCancelationRequest(body) {
  return postMethodNormalAPI(createCancelationRequestURL, body);
}
export async function createBooking(body) {
  return postMethodNormalAPI(createBookingURL, body);
}
export async function addTrips(body) {
  return postMethodNormalAPI(tripsURL, body);
}
export async function getTripById(id) {
  return getMethodNormal(`${tripByIDURL}${id}`);
}
export async function getBookingByCustomer(id) {
  return getMethodNormal(`${getBookingByCustomerIdURL}${id}`);
}
export async function tripByCityId(id) {
  return getMethodNormal(`${tripByCityIdURL}${id}`);
}
export async function getTripsByCategoryId(id) {
  return getMethodNormal(`${getTripsByCategoryIdURL}${id}`);
}
export async function addCityToTrip(body) {
  return postMethodNormalAPI(addCityToTripURL, body);
}
export async function tripByCityName(body) {
  return postMethodNormalAPI(tripByCityNameURL, body);
}
export async function getPriceInfo(body) {
  return postMethodNormalAPI(getPriceInfoURL, body);
}
export async function updateTestimonials(body) {
  return postMethodMultiPartAPI(updateTestimonialsURL, body);
}
export async function removeTripImage(body) {
  return postMethodNormalAPI(removeTripImageURL, body);
}
export async function deleteCityFromTrip(body) {
  return postMethodNormalAPI(deleteCityFromTripURL, body);
}
export async function addImageToTrip(body) {
  return postMethodMultiPartAPI(addImageToTripURL, body);
}
export async function updateTripData(body) {
  return postMethodNormalAPI(updateTripDataURL, body);
}
export async function addDatesToTrips(body) {
  return postMethodNormalAPI(addDatesToTripsURL, body);
}
export async function addPriceToTrips(body) {
  return postMethodNormalAPI(addPriceToTripsURL, body);
}
export async function addScheduleToTrip(body) {
  return postMethodMultiPartAPI(addScheduleToTripURL, body);
}
export async function addSliderImagesURL(body) {
  return postMethodMultiPartAPI(sliderImagesURL, body);
}
export async function deleteSliderImage(id) {
  return getMethodNormal(`${deleteSliderImageURL}${id}`);
}
export async function deleteVideo(id) {
  return getMethodNormal(`${deleteVideoURL}${id}`);
}
export async function getSliderImages(body) {
  return getMethodNormal(sliderImagesURL, body);
}
export async function addSection(body) {
  return postMethodNormalAPI(sectionsURL, body);
}
export async function updateSection(body) {
  return postMethodNormalAPI(updateSectionURL, body);
}
export async function addTripInSections(body) {
  return postMethodNormalAPI(addTripInSectionsURL, body);
}
export async function deleteTripFromSection(body) {
  return postMethodNormalAPI(deleteTripFromSectionURL, body);
}

export async function addFaqToTrip(body) {
  return postMethodNormalAPI(addFaqToTripURL, body);
}
export async function removeTripFaq(body) {
  return postMethodNormalAPI(removeTripFaqURL, body);
}
export async function getSections(body) {
  return getMethodNormal(sectionsURL, body);
}
export async function getSectionById(id) {
  return getMethodNormal(`${getSectionByIdURL}${id}`);
}

//dealer URL
const getDealersURL = `${API_URL}/dealer`;
const loginDealerURL = `${API_URL}/dealer/login`;
const activateDealerURL = `${API_URL}/dealer/activate`;
const getDealerByIdURL = `${API_URL}/dealer/`;
const getDealerByCodeURL = `${API_URL}/dealer/filter?code=`;
const generateOTPDealerURL = `${API_URL}/dealer/otp/`;
const verifyOTPDealerURL = `${API_URL}/dealer/verify_otp/`;
const getDealerCustomersURL = `${API_URL}/dealer/customers/`;
const getDealerPurchasesURL = `${API_URL}/dealer/purchases/`;
const getAllPurchasesURL = `${API_URL}/purchase`;
const getPurchasesByStatusURL = `${API_URL}/purchase/filter?status=`;
const getDealersByStatusURL = `${API_URL}/dealer/statusFilter?isactive=`;
const getDealerByStateURL = `${API_URL}/dealer/stateFilter?state=`;
const customerLeaderBoardURL = `${API_URL}/leaderboard/customer`;
const retailerLeaderBoardURL = `${API_URL}/leaderboard/retailer`;

//customer url
const getCustomerByIdURL = `${API_URL}/customer/`;
const getCustomersStage23URL = `${API_URL}/getstage23customers`;
const checkCouponsURL = `${API_URL}/checkCoupons`;

const generateOTPCustomerURL = `${API_URL}/customer/otp/`;
const verifyOTPCustomerURL = `${API_URL}/customer/otp/validate`;
const getCustomerPurchasesURL = `${API_URL}/customer/purchases/`;
const getCustomerTokensURL = `${API_URL}/customer/tokens`;
const updateCustomerPurchaseURL = `${API_URL}/purchase/`;
const updatePurchaseInvoiceReuploadURL = `${API_URL}/update_purchase_invoice`;
const customerLoginOtpWithoutDealerURL = `${API_URL}/customer/loginotp/`;

// admin url
const getAllAdminStatusURL = `${API_URL}/purchase/allstats`;

//API URLS END

const getContactsURL = `${API_URL}/getContacts`;

//login
export async function loginApiFunction(body) {
  return postMethodNormalAPI(loginURL, body);
}
export async function loginApiFunctionDealer(body) {
  return postMethodNormalAPI(loginDealerURL, body);
}
//Customers
// export async function getCustomers(){
//   return getMethodNormal(getCustomersURL);
// }
export async function getCustomersStage23() {
  return getMethodNormal(getCustomersStage23URL);
}

//Dealers
export async function getDealers(token) {
  return getMethodNormal(getDealersURL);
}
export async function addDealerData(body) {
  return postMethodNormalAPI(getDealersURL, body);
}
export async function activateDealerAPI(body) {
  return postMethodMultiPartAPI(activateDealerURL, body);
}
export async function geDealerById(id) {
  return getMethodNormal(`${getDealerByIdURL}${id}`);
}
export async function geDealerByCode(code) {
  return getMethodNormal(`${getDealerByCodeURL}${code}`);
}
export async function generateOTPDealer(mobile) {
  return postMethodNormalAPI(`${generateOTPDealerURL}${mobile}`);
}
export async function verifyDealerOTP(body) {
  return postMethodNormalAPI(verifyOTPDealerURL, body);
}
export async function getDealerCustomer(id) {
  return getMethodNormal(`${getDealerCustomersURL}${id}`);
}
export async function getDealerPurchases(id) {
  return getMethodNormal(`${getDealerPurchasesURL}${id}`);
}
export async function getAllPurchases() {
  return getMethodNormal(`${getAllPurchasesURL}`);
}
export async function getDealersByStatus(status) {
  return getMethodNormal(`${getDealersByStatusURL}${status}`);
}
export async function getDealerByState(state) {
  return getMethodNormal(`${getDealerByStateURL}${state}`);
}

//customer
export async function generateOTPCustomer(mobile) {
  return getMethodNormal(`${generateOTPCustomerURL}${mobile}`);
}
export async function getCustomerPurchases(customerId) {
  return getMethodNormal(`${getCustomerPurchasesURL}${customerId}`);
}
export async function verifyOTPCustomer(body) {
  return postMethodNormalAPI(verifyOTPCustomerURL, body);
}
// export async function addInvoice (body){
//   return postMethodMultiPartAPI(getCustomersURL,body);
// }
export async function getCustomerById(id) {
  return getMethodNormal(`${getCustomerByIdURL}${id}`);
}
export async function getCustomerTokens(id) {
  return getMethodNormal(`${getCustomerTokensURL}/${id}`);
}
export async function checkCoupons(body) {
  return postMethodNormalAPI(checkCouponsURL, body);
}
export async function customerLoginOtpWithoutDealer(mobile) {
  return getMethodNormal(`${customerLoginOtpWithoutDealerURL}${mobile}`);
}
export async function updateCustomerPurchase(purchaseID, body) {
  return postMethodNormalAPIPUT(
    `${updateCustomerPurchaseURL}${purchaseID}`,
    body
  );
}
export async function getPurchaseDetailsById(purchaseID) {
  return getMethodNormal(`${updateCustomerPurchaseURL}${purchaseID}`);
}
export async function updateCustomerPurchaseReupload(body) {
  return postMethodMultiPartAPI(`${updatePurchaseInvoiceReuploadURL}`, body);
}
export async function getContacts(token) {
  return getMethodNormal(getContactsURL);
}

// Functions end //

// admin  panel sections
export async function getPurchasesByStatus(status) {
  return getMethodNormal(`${getPurchasesByStatusURL}${status}`);
}
export async function getAllAdminStatus(status) {
  return getMethodNormal(`${getAllAdminStatusURL}`);
}

export async function getCustomerLeaderBoard() {
  return getMethodNormal(`${customerLeaderBoardURL}`);
}
export async function getRetailerLeaderBoard() {
  return getMethodNormal(`${retailerLeaderBoardURL}`);
}
