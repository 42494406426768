import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { createCancelationRequest, getCustomerById } from "../api";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@mui/material";
import Select from "react-select";

export const LayoutCustomer = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  // console.log("selectedOptions", selectedOptions);
  const [options, setOptions] = useState([]);
  // const options = [
  //   { value: "option1", label: "Option 1" },
  //   { value: "option2", label: "Option 2" },
  //   { value: "option3", label: "Option 3" },
  //   { value: "option4", label: "Option 4" },
  // ];

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChange = (event) => {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedOptions(selectedValues);
  };

  const userInfo = useSelector((state) => state.userInformation);
  // console.log("userInfo", userInfo);
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cancellationType, setCancellationType] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [largeShow, setlargeShow] = useState(false);
  const largemodalClose = () => setlargeShow(false);
  const largemodalShow = () => setlargeShow(true);
  const [modalData, setModalData] = useState([]);
  const handleCheckboxChange = (event) => {
    // console.log("event", event.target.checked);
    setIsChecked(event.target.checked);
    // if(event.target.checked === true)
    // {
    //   setIsChecked(false);
    // }
    // else
    // {
    //   setIsChecked(true);

    // }
  };
  useEffect(() => {
    getData();
  }, []);
  const closeModalFunction = () => {
    largemodalClose();
    setModalData([]);
  };

  const formsSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const selectedOptionsName = selectedOptions
      .map((item) => item.value)
      .join(", ");

    formData.append("bookingID", modalData?._id);
    formData.append("cancellationNamesFromCustomer", selectedOptionsName);
    formData.append("bookingStatus", 'Cancelled');
    // console.log("formData", formData);
    const formJson = Object.fromEntries(formData.entries());
    addData(formJson);
    setLoading(false);
  };
  const addData = async (body) => {
    setError("");
    // setSuccessMessage("");

    setLoading(true);

    try {
      const apiData = await createCancelationRequest(body);
      if (apiData.error) {
        setError(apiData.error);
      } else {
        getData();
        // handleClose3();
        alert("Cancellation Request Submitted");
        largemodalClose();

        // toast.success(
        //   <p className="text-white tx-16 mb-0">
        //     Success : Enquiry Submitted !
        //   </p>,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //     hideProgressBar: false,
        //     autoClose: 3000,
        //     theme: "colored",
        //   }
        // );
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        // setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        // setErrorToast(err.message);
      } else {
        setError("something went wrong");
        // setErrorToast("something went wrong");
      }
    }

    // hideContactUsModal();
    window.scrollTo({ top: 0, left: 0 });
    setLoading(false);
  };

  function getMonthNumber(monthAbbreviation) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return months.indexOf(monthAbbreviation) + 1;
  }

  const getData = async (body) => {
    try {
      const user_id = localStorage.getItem("user_id");
      const apiData = await getCustomerById(user_id);
      if (apiData.error) {
        console.error("error", apiData.error);
      } else {
        // console.log("getData", apiData.data);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
    }
  };
  return (
    <div>
      <div>
        <div className="user-canvas-container">
          <div className="side-menu-close">
            <i className="la la-times" />
          </div>
          {/* end menu-toggler */}
          <div className="user-canvas-nav">
            <div className="section-tab section-tab-2 text-center pt-4 pb-3 pl-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="notification-tab"
                    data-toggle="tab"
                    href="#notification"
                    role="tab"
                    aria-controls="notification"
                    aria-selected="false"
                  >
                    Notifications
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="account-tab"
                    data-toggle="tab"
                    href="#account"
                    role="tab"
                    aria-controls="account"
                    aria-selected="true"
                  >
                    Account
                  </a>
                </li>
              </ul>
            </div>
            {/* end section-tab */}
          </div>
          <div className="user-canvas-nav-content">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="notification"
                role="tabpanel"
                aria-labelledby="notification-tab"
              >
                <div className="user-sidebar-item">
                  <div className="notification-item">
                    <div className="list-group drop-reveal-list">
                      <a
                        href="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="msg-body d-flex align-items-center">
                          <div className="icon-element flex-shrink-0 mr-3 ml-0">
                            <i className="la la-bell" />
                          </div>
                          <div className="msg-content w-100">
                            <h3 className="title pb-1">
                              Your request has been sent
                            </h3>
                            <p className="msg-text">2 min ago</p>
                          </div>
                        </div>
                        {/* end msg-body */}
                      </a>
                      <a
                        href="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="msg-body d-flex align-items-center">
                          <div className="icon-element bg-2 flex-shrink-0 mr-3 ml-0">
                            <i className="la la-check" />
                          </div>
                          <div className="msg-content w-100">
                            <h3 className="title pb-1">
                              Your account has been created
                            </h3>
                            <p className="msg-text">1 day ago</p>
                          </div>
                        </div>
                        {/* end msg-body */}
                      </a>
                      <a
                        href="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="msg-body d-flex align-items-center">
                          <div className="icon-element bg-3 flex-shrink-0 mr-3 ml-0">
                            <i className="la la-user" />
                          </div>
                          <div className="msg-content w-100">
                            <h3 className="title pb-1">Your account updated</h3>
                            <p className="msg-text">2 hrs ago</p>
                          </div>
                        </div>
                        {/* end msg-body */}
                      </a>
                      <a
                        href="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="msg-body d-flex align-items-center">
                          <div className="icon-element bg-4 flex-shrink-0 mr-3 ml-0">
                            <i className="la la-lock" />
                          </div>
                          <div className="msg-content w-100">
                            <h3 className="title pb-1">
                              Your password changed
                            </h3>
                            <p className="msg-text">Yesterday</p>
                          </div>
                        </div>
                        {/* end msg-body */}
                      </a>
                      <a
                        href="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="msg-body d-flex align-items-center">
                          <div className="icon-element bg-5 flex-shrink-0 mr-3 ml-0">
                            <i className="la la-envelope" />
                          </div>
                          <div className="msg-content w-100">
                            <h3 className="title pb-1">Your email sent</h3>
                            <p className="msg-text">Yesterday</p>
                          </div>
                        </div>
                        {/* end msg-body */}
                      </a>
                      <a
                        href="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="msg-body d-flex align-items-center">
                          <div className="icon-element bg-6 flex-shrink-0 mr-3 ml-0">
                            <i className="la la-envelope" />
                          </div>
                          <div className="msg-content w-100">
                            <h3 className="title pb-1">Your email changed</h3>
                            <p className="msg-text">Yesterday</p>
                          </div>
                        </div>
                        {/* end msg-body */}
                      </a>
                    </div>
                  </div>
                  {/* end notification-item */}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="account"
                role="tabpanel"
                aria-labelledby="account-tab"
              >
                <div className="user-action-wrap user-sidebar-panel">
                  <div className="notification-item">
                    <a
                      href="user-dashboard-profile.html"
                      className="dropdown-item"
                    >
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-sm flex-shrink-0 mr-2">
                          <img
                            src="https://placehold.co/63x63"
                            alt="team-img"
                          />
                        </div>
                        <span className="font-size-14 font-weight-bold">
                          {mainData && mainData?.firstName}
                        </span>
                      </div>
                    </a>
                    <div className="list-group drop-reveal-list user-drop-reveal-list">
                      <a
                        href="user-dashboard-profile.html"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="msg-body">
                          <div className="msg-content">
                            <h3 className="title">
                              <i className="la la-user mr-2" />
                              My Profile
                            </h3>
                          </div>
                        </div>
                        {/* end msg-body */}
                      </a>

                      <div className="section-block" />
                      <Link
                        to="/"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="msg-body">
                          <div className="msg-content">
                            <h3 className="title">
                              <i className="la la-power-off mr-2" />
                              Logout
                            </h3>
                          </div>
                        </div>
                        {/* end msg-body */}
                      </Link>
                    </div>
                  </div>
                  {/* end notification-item */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end user-canvas-container */}
        {/* ================================
 END USER CANVAS MENU
================================= */}
        {/* ================================
 START DASHBOARD NAV
================================= */}
        <div className="sidebar-nav">
          <div className="sidebar-nav-body">
            <div className="side-menu-close">
              <i className="la la-times" />
            </div>
            {/* end menu-toggler */}
            <div className="author-content">
              <div className="d-flex align-items-center">
                <div className="author-img avatar-sm">
                  <img
                    src="https://placehold.co/63x63/orange/white?text=avatar"
                    alt="testimonial image"
                  />
                </div>
                <div className="author-bio">
                  <h4 className="author__title">
                    {mainData && mainData?.mobile}
                  </h4>
                  {/* <span className="author__meta">Member Since May 2017</span> */}
                </div>
              </div>
            </div>
            <div className="sidebar-menu-wrap">
              <ul className="sidebar-menu list-items">
                <li>
                  <Link to="/">
                    <i className="la la-home mr-2 text-color-6" />
                    Home
                  </Link>
                </li>
                <li className="page-active">
                  <Link to="/user">
                    <i className="la la-dashboard mr-2" />
                    Dashboard
                  </Link>
                </li>
              </ul>
            </div>
            {/* end sidebar-menu-wrap */}
          </div>
        </div>
        {/* end sidebar-nav */}
      </div>
      <div>
        <section className="dashboard-area">
          <div className="dashboard-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="menu-wrapper">
                    <div className="logo mr-5">
                      <Link to="/">
                        <img
                          src="images/logo2.png"
                          style={{ height: "100px" }}
                          alt="logo"
                        />
                      </Link>
                      <div className="menu-toggler">
                        <i className="la la-bars" />
                        <i className="la la-times" />
                      </div>
                      {/* end menu-toggler */}
                      <div className="user-menu-open">
                        <i className="la la-user" />
                      </div>
                      {/* end user-menu-open */}
                    </div>
                    {/* <div className="dashboard-search-box">
              <div className="contact-form-action">
                <form action="#">
                  <div className="form-group mb-0">
                    <input className="form-control" type="text" name="text" placeholder="Search" />
                    <button className="search-btn"><i className="la la-search" /></button>
                  </div>
                </form>
              </div>
            </div> */}
                  </div>
                  {/* end menu-wrapper */}
                </div>
                {/* end col-lg-12 */}
              </div>
              {/* end row */}
            </div>
            {/* end container-fluid */}
          </div>
          {/* end dashboard-nav */}
          <div className="dashboard-content-wrap">
            <div className="dashboard-bread dashboard--bread">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="breadcrumb-content">
                      <div className="section-heading">
                        <h2 className="sec__title font-size-30 text-white">
                          My Booking
                        </h2>
                      </div>
                    </div>
                    {/* end breadcrumb-content */}
                  </div>
                  {/* end col-lg-6 */}
                  <div className="col-lg-6">
                    <div className="breadcrumb-list text-right">
                      <ul className="list-items">
                        <li>
                          <Link to="/" className="text-white">
                            Home
                          </Link>
                        </li>
                        <li>Dashboard</li>
                        <li>My Booking</li>
                      </ul>
                    </div>
                    {/* end breadcrumb-list */}
                  </div>
                  {/* end col-lg-6 */}
                </div>
                {/* end row */}
              </div>
            </div>
            {/* end dashboard-bread */}
            <div className="dashboard-main-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-box">
                      <div className="form-title-wrap">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <h3 className="title">Booking Results</h3>
                            <p className="font-size-14">
                              Showing {mainData?.bookings?.length} entries
                            </p>
                          </div>
                          {/* <span>Total Bookings <strong className="color-text">()</strong></span> */}
                        </div>
                      </div>
                      <div className="form-content">
                        <div className="table-form table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                {/* <th scope="col">Type</th> */}
                                <th scope="col">Title</th>
                                <th scope="col">Location</th>
                                <th scope="col">Order Date</th>
                                <th scope="col">Execution Date</th>
                                <th scope="col">Participant Count</th>
                                <th scope="col">Price</th>
                                <th scope="col">Partial Payment</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {mainData &&
                                mainData?.bookings?.map((data, i) => {
                                  // console.log("bookingData",data);
                                  const dateString = data?.trip_date;
                                  const dateParts = dateString.split("-");
                                  const year = parseInt(dateParts[2]);
                                  const month = getMonthNumber(dateParts[1]);
                                  const day = parseInt(dateParts[0]);

                                  const targetDate = new Date(
                                    year,
                                    month - 1,
                                    day
                                  ); // Month is 0-indexed, so subtract 1

                                  // Get the current date and time
                                  const currentDate = new Date();

                                  // Calculate the difference in milliseconds between the target date and the current date
                                  const timeDifference =
                                    targetDate - currentDate;

                                  // Calculate the difference in hours
                                  const hoursDifference =
                                    timeDifference / (1000 * 60 * 60);
                                  let showCancelbutton = true;
                                  if (hoursDifference < 24) {
                                    showCancelbutton = false;
                                  }

                                  let showPartialPaymentLink = false;
                                  if (
                                    data?.partial_payment[1]?.paymentStatus ===
                                    "Unpaid"
                                  ) {
                                    showPartialPaymentLink = true;
                                  }
                                  // else {
                                  //   console.log("Show cancel booking function");
                                  // }

                                  return (
                                    <tr>
                                      {/* <th scope="row">Train</th> */}
                                      <td>
                                        <div className="table-content">
                                          <h3 className="title">
                                            {data?.trip_name}
                                          </h3>
                                        </div>
                                      </td>
                                      <td> {data?.trip_city} City</td>
                                      <td>{data?.createdAt}</td>
                                      <td>{data?.trip_date}</td>
                                      <td>{data?.participant?.length}</td>
                                      <td>{data?.trip_amount} R.s</td>
                                      <td>
                                        {showPartialPaymentLink ? (
                                          <a
                                            target="_blank"
                                            className="theme-btn theme-btn-small"
                                            href={`/partialPay/${data?._id}`}
                                            rel="noreferrer"
                                          >
                                            Pay
                                          </a>
                                        ) : (
                                          <span>NA</span>
                                        )}
                                      </td>
                                      <td>
                                        <span className="badge py-1 px-2">
                                          {data?.bookingStatus}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="table-content">
                                          {showCancelbutton === true && data?.bookingStatus !== "Cancelled" ? (
                                            <button
                                              className="theme-btn theme-btn-small"
                                              onClick={() => {
                                                setModalData(data);
                                                let participant =
                                                  data?.participant;
                                                const formattedParticipantArray =
                                                  participant.map(
                                                    (person, index) => ({
                                                      value: `${person.firstName} ${person.lastName}`,
                                                      label: `${person.firstName} ${person.lastName}`,
                                                    })
                                                  );

                                                // console.log(
                                                //   "formattedParticipantArray",
                                                //   formattedParticipantArray
                                                // );

                                                setOptions(
                                                  formattedParticipantArray
                                                );

                                                largemodalShow();
                                              }}
                                            >
                                              Cancel Request
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          <Modal
                            size="lg"
                            show={largeShow}
                            onHide={largemodalClose}
                          >
                            <ModalHeader>
                              <ModalTitle>Create Cancel Request</ModalTitle>
                              <span
                                className="d-flex ms-auto"
                                onClick={largemodalClose}
                              >
                                <i className="fe fe-x ms-auto"></i>
                              </span>
                            </ModalHeader>
                            <form
                              method="post"
                              onSubmit={formsSubmit}
                              // autocomplete="off"
                              encType="multipart/form-data"
                            >
                              <ModalBody style={{ fontFamily: "Montserrat" }}>
                                <div className="form-group">
                                  <label htmlFor="">
                                    Select Cancellation Type{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <select
                                    name="cancellationType"
                                    className="form-control"
                                    id=""
                                    onChange={(e) => {
                                      setCancellationType(e.target.value);
                                    }}
                                  >
                                    <option value="Full">
                                      Full Cancellation
                                    </option>
                                    <option value="Partial">
                                      Partial Cancellation
                                    </option>
                                  </select>
                                </div>
                                {cancellationType === "Partial" && (
                                  <>
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Select Participant To Cancel
                                        {/* {modalData?.participant_count}{" "}
                                        Participant) */}
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                      <Select
                                        isMulti
                                        options={options}
                                        value={selectedOptions}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </>
                                )}

                                <div className="form-group">
                                  <label htmlFor="">
                                    Enter Cancellation Reason{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    required
                                    name="cancellationNotesFromCustomer"
                                    placeholder="Enter Reason"
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group">
                                  <FormControlLabel
                                    value="end"
                                    control={
                                      <Checkbox
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    labelPlacement="end"
                                  />
                                  <span
                                    className="basicPartiCipant"
                                    style={{
                                      fontWeight: "600",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    I accept the terms and conditions mentioned
                                    in the cancellation policy.
                                  </span>
                                  <a
                                    href="http://trekpanda.co.in/CancellationPolicy"
                                    target="_blank"
                                    className="basicPartiCipant"
                                    style={{
                                      fontWeight: "600",
                                      marginLeft: "2px",
                                      color: "#36b54a",
                                    }}
                                    rel="noreferrer"
                                  >
                                    (View)
                                  </a>
                                </div>
                              </ModalBody>

                              <ModalFooter>
                                <Button
                                  disabled={!isChecked}
                                  variant="primary"
                                  type="submit"
                                >
                                  Submit Request
                                </Button>
                                <Button
                                  variant="secondary"
                                  onClick={closeModalFunction}
                                >
                                  Close
                                </Button>
                              </ModalFooter>
                            </form>
                          </Modal>
                        </div>
                      </div>
                    </div>
                    {/* end form-box */}
                  </div>
                  {/* end col-lg-12 */}
                </div>
                {/* end row */}

                {/* end row */}
                <div className="border-top mt-5" />
              </div>
              {/* end container-fluid */}
            </div>
            {/* end dashboard-main-content */}
          </div>
          {/* end dashboard-content-wrap */}
        </section>

        {/* ================================
    END DASHBOARD AREA
================================= */}
        {/* start scroll top */}
        <div id="back-to-top">
          <i className="la la-angle-up" title="Go top" />
        </div>
      </div>
    </div>
  );
};
