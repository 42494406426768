import React, { useState, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import { Icon as Iconfiy } from "@iconify/react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, useParams } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createBooking, getbookingDetailsById, updateBooking } from "../api";
import { ToastContainer, toast } from "react-toastify";
import scannerImage from "../assets/scanner.jpeg";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { updateUserInformation } from "../redux/actions";
export const PartialPay = () => {
  let { id } = useParams();
  const [mainData, setMainData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [status, setStatus] = useState('');
  const setErrorToast = (errorFromBackend) => {
    toast.error(
      <p className="text-white tx-16 mb-0">
        Error: {error.length > 0 ? error : errorFromBackend}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
        theme: "colored",
      }
    );
  };

  console.log("id", id);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let payload = {
        id: id,
      };
      const apiData = await getbookingDetailsById(payload);
      if (apiData.error) {
        setError(apiData.error);
      } else {
        console.log("bookingData", apiData.data);
        if (apiData.data) {
          if (apiData.data.partial_payment.length > 0) {
            setMainData(apiData.data);
            setTotalAmount(
              Number(apiData?.data?.partial_payment[1].amount).toFixed(2)
            );
            // alert(apiData?.data?.partial_payment[1].paymentStatus);
            setStatus(apiData?.data?.partial_payment[1].paymentStatus);
          } else {
            setShowError(true);
          }
        } else {
          setShowError(true);
        }
      }
    } catch (err) {
      setShowError(true);
      //   console.log("err", err.name);
      //   if (err.response) {
      //     setError(err.response.data.message);
      //     setErrorToast(err.response.data.message);
      //   } else if (err.name === "AxiosError") {
      //     setError(err.message);
      //     setErrorToast(err.message);
      //   } else {
      //     setError("something went wrong");
      //     setErrorToast("something went wrong");
      //   }
    }
    setLoading(false);
  }, []);

  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [partialAmount, setPartialAmount] = useState(0);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [customMessage, setCustomMessage] = useState(null);

  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  // const [userInfo, setParticipantsData] = useState([]);
  // useEffect(() => {
  //   console.log("userInfo?.participant on mount:", userInfo?.participant);
  // }, []);

  const handleCheckboxChange = (event) => {
    console.log("event", event.target.checked);
    setIsChecked(event.target.checked);
    // if(event.target.checked === true)
    // {
    //   setIsChecked(false);
    // }
    // else
    // {
    //   setIsChecked(true);

    // }
  };

  const customer_id = localStorage.getItem("customer_id");
  const user_mobile = localStorage.getItem("user_mobile");
  const [largeShow, setlargeShow] = useState(false);

  const setlargeShowFunctionn = () => {
    setSelectedImage1("");
    setlargeShow(true);
  };
  const [bookingStatus, setBookingStatus] = useState("Confirmed");
  const [paymentType, setPaymentType] = useState("Full");
  const largemodalClose = () => setlargeShow(false);
  const largemodalShow = () => setlargeShow(true);
  const [finalAmountWIthPaymentGateWay, setFinalAmountWIthPaymentGateWay] =
    useState(0);
  const [razorpayFees, setRazorpayFees] = useState(0);
  const [razorpayFeesGst, setRazorpayFeesGstNew] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    if (!customer_id) {
      // navigate("/");
    } else {
    }

    return setIsChecked(false);
  }, [navigate, customer_id]);
  const Razorpay = useRazorpay();

  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  // const isHaveAllValues = userInfo?.participant.length > 0 && userInfo?.participant?.every(obj => {
  //   // Use Object.values to get an array of property values, then check if none of them are empty
  //   return Object.values(obj).every(value => value !== "");
  // });

  // console.log("isHaveAllValues",isHaveAllValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addData = async (paymentID) => {
    setError("");
    setSuccessMessage("");

    setLoadingNew(true);

    if (true) {
      let newpartial_payment = mainData?.partial_payment;

      newpartial_payment[1].partial_paymentId = paymentID;
      newpartial_payment[1].paymentStatus = "Paid";
      newpartial_payment[1].time = new Date();
      newpartial_payment[1].date = new Date();
      let body = {
        bookingID: id,
        partial_payment: newpartial_payment,
      };

      console.log("body", body);
      try {
        const apiData = await updateBooking(body);
        if (apiData.error) {
          setError(apiData.error);
        } else {
          navigate("/OrderPlaced");
          setLoadingNew(false);
          // toast.success(
          //   <p className="text-white tx-16 mb-0">Success : Trips Added !</p>,
          //   {
          //     position: toast.POSITION.TOP_RIGHT,
          //     hideProgressBar: false,
          //     autoClose: 3000,
          //     theme: "colored",
          //   }
          // );
        }
      } catch (err) {
        console.log("err123", err);
        if (err.response) {
          setError(err.response.data.message);
          setErrorToast(err.response.data.message);
        } else if (err.name === "AxiosError") {
          setError(err.message);
          setErrorToast(err.message);
        } else {
          setError("something went wrong");
          setErrorToast("something went wrong");
        }
      }

      setLoadingNew(false);

      // setLoading(false);
    } else {
      alert("participant data error !! please refresh page try again");
    }
  };
  const handlePayment = useCallback(() => {
    // setLoadingNew(true);
    // navigate("/CustomerRegistration");

    const order = "test";
    const options = {
      // key: "rzp_test_R2v97gBF868mnM",
      key: "rzp_test_cA6phebXWf464T",
      amount: totalAmount * 100,
      currency: "INR",
      name: "Trek Panda",
      description: mainData.trip_name,
      image:
        "http://website.aanshulindia.in/static/media/logo.5342288cd312fec5f658.png",
      order_id: order.id,
      handler: (res) => {
        console.log("handler", res);
        if (res.razorpay_payment_id) {
          setTimeout(() => {
            addData(res.razorpay_payment_id);
          }, 2000); // 2000 milliseconds (2 seconds)
        } else {
          // navigate("/OrderCancelled");
          navigate("/OrderCancelled");
          // alert("Error While adding payment");
        }
      },
      prefill: {
        name: `${mainData?.participant[0]?.firstName} ${mainData?.participant[0]?.lastName}`,
        email: `${mainData?.participant[0]?.email}`,
        contact: `${mainData?.participant[0]?.mobile}`,
      },
      notes: {
        address: "Booking Details",
      },
      theme: {
        color: "#36b54a",
      },
      checkout: {
        method: {
          netbanking: 0,
          card: 1,
          upi: 0,
          wallet: 0,
        },
      },
    };

    const rzpay = new Razorpay(options);
    console.log("rzpay", rzpay);
    rzpay.on("payment.failed", function (response) {
      navigate("/OrderCancelled");

      // alert("Error While doing payment,please try again");
    });
    rzpay.open();
  }, [Razorpay, addData, navigate, partialAmount, paymentType]);

  useEffect(() => {
    getData();
  }, id);
  return (
    <div style={{ position: "relative" }}>
      <ToastContainer />

      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        {/* <h2 className="loginScreenTextpartial">Partial Pay</h2> */}
      </div>

      {loading ? (
        <>
          <div className="loading-container">
            <div className="loading">Loading...</div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div
            className="phoneNumberDivLoginRegistration padding12"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor: "red",
            }}
          >
            <p className="selectParticipantTitle">{mainData?.trip_name}</p>
            <p
              className="selectParticipantTitle"
              style={{
                color: "#717171",
                fontSize: "1rem",
                lineHeight: "1.8",
                fontWeight: "500",
              }}
            >
              {mainData?.trip_city} <br />
              {mainData.trip_date}
            </p>
          </div>
          
          {status === "Paid" ? (
             <>
             <div className="padding12 mt-4">
  <p>Partial Payment Received! 😊 Thank you for your support. </p>
  {/* <p>Feel free to complete the remaining balance at your convenience.</p> */}
</div>

           </>
          ) : showError ? (
            <>
              <div className="padding12">
                <p>Something Went Wrong !!! , Please Try Again</p>
              </div>
            </>
          ) : (
            <div className="padding12">
              <div>
                <>
                  <Button
                    sx={{
                      marginTop: "16px",
                      width: "300.39px",
                      height: "38px",
                      background: "#36b54a",
                      border: "1px solid #36b54a",
                      color: "#FFFFFF",
                      // marginBottom:"5px",
                      padding: "7px 16.39px 7px 16px",

                      borderRadius: "9.6px",
                      fontFamily: "Montserrat",
                      fontWeight: "700",

                      // marginBottom:"10px"
                      // marginBottom:"-20px"
                    }}
                    onClick={handlePayment}
                  >
                    Pay Online {totalAmount} ₹
                    <Iconfiy
                      width="20"
                      height="28"
                      color="#fff"
                      icon="uil:arrow-right"
                    />{" "}
                  </Button>
                  {/* {userInfo?.showOfflineBooking && (
                      <Button
                        sx={{
                          marginLeft: "20px",
                          marginTop: "16px",
                          width: "300.39px",
                          height: "38px",
                          background: "#36b54a",
                          border: "1px solid #36b54a",
                          color: "#FFFFFF",
                          // marginBottom:"5px",
                          padding: "7px 16.39px 7px 16px",

                          borderRadius: "9.6px",
                          fontFamily: "Montserrat",
                          fontWeight: "700",

                          // marginBottom:"10px"
                          // marginBottom:"-20px"
                        }}
                        // disabled={!isChecked}
                        onClick={() => {
                          setlargeShowFunctionn();
                        }}
                      >
                        Pay Offline{" "}
                        {paymentType === "Full" ? total_fees : partialAmount}
                        ₹
                        <Iconfiy
                          width="20"
                          height="28"
                          color="#fff"
                          icon="uil:arrow-right"
                        />{" "}
                      </Button>
                    )} */}
                </>
              </div>

              <br />
              <br />

              <br />
              <br />

              <Modal size="lg" show={largeShow} onHide={largemodalClose}>
                <ModalHeader>
                  <ModalTitle> Pay Through UPI</ModalTitle>
                  <span className="d-flex ms-auto" onClick={largemodalClose}>
                    <i className="fe fe-x ms-auto"></i>
                  </span>
                </ModalHeader>
                <ModalBody>
                  <div className="container">
                    <h5>
                      Amount To Pay <b>{}₹ </b>
                    </h5>
                    <br />
                    <h6>
                      Please Pay <b>{} ₹ </b> To Following UPI Details, And
                      Upload Screenshot Here{" "}
                    </h6>
                    <ol style={{ marginLeft: "20px", marginTop: "20px" }}>
                      <li>
                        UPI SCANNER : <img src={scannerImage} alt="" />
                      </li>
                      <li style={{ marginTop: 10 }}>
                        UPI ID : TREKPANDAINDIALLP.eazypay@icici
                      </li>
                      <li style={{ marginTop: 10 }}>
                        <b> Note :</b> It Will Take 24 Hours to get Confirmation
                      </li>
                    </ol>

                    <div className="form-group">
                      <label htmlFor="">
                        Upload Screenshot <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        required
                        accept="image/*"
                        onChange={(event) => {
                          console.log("change image", event.target.files[0]);
                          setSelectedImage1(event.target.files[0]);
                        }}
                      />
                      {/* <input type="file" className="form-control" name="" id="" /> */}
                    </div>
                    <div className="form-group">
                      {selectedImage1?.name?.length > 0 ? (
                        <Button
                          sx={{
                            marginTop: "16px",
                            width: "250.39px",
                            height: "38px",
                            background: "#36b54a",
                            border: "1px solid #36b54a",
                            color: "#FFFFFF",
                            // marginBottom:"5px",
                            padding: "7px 16.39px 7px 16px",

                            borderRadius: "9.6px",
                            fontFamily: "Montserrat",
                            fontWeight: "700",

                            // marginBottom:"10px"
                            // marginBottom:"-20px"
                          }}
                          onClick={() => {
                            addData("offline");
                          }}
                        >
                          Confirm Booking
                          <Iconfiy
                            width="20"
                            height="28"
                            color="#fff"
                            icon="uil:arrow-right"
                          />{" "}
                        </Button>
                      ) : (
                        <div className="alert-danger">
                          Please Upload Payment Screen First
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button variant="secondary" onClick={largemodalClose}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          )}
        </>
      )}
    </div>
  );
};
